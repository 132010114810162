.help-button {
  position: fixed;
  bottom: 180px;
  right: 30px;
  padding: 16px;
  border-radius: 16px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 100;

  .help-button-close {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  img {
    max-width: 100%;
    height: 36px;
  }

  &::after {
    content: "";
    position: absolute;
    right: 18px;
    bottom: -15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #fff;
  }
}

.help-button-icon .ant-float-btn-body,
.help-button-icon svg {
  background: #25d366 !important;
  color: #ffffff !important;;
}

.help-button-icon:hover,
.help-button-icon:hover .ant-float-btn-body,
.help-button-icon:hover svg {
  background: #ffffff !important;
  color: #25d366 !important;
}
